import React from "react";
import {Button, Card, Offcanvas} from "react-bootstrap";
import axios from "axios";
import HTMLReactParser from "html-react-parser";

class UniversalNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            updateNeeded: props.updateNeeded,
            show: props.show,
            news: [],
            viewed: []
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleViewed = this.handleViewed.bind(this);
    }

    componentDidMount() {
        this.loadNotificationsData()
    }


    static getDerivedStateFromProps(newProps, prevState) {

        return {
            show : newProps.show,
            updateNeeded: newProps.updateNeeded,
        };
    }

    handleClose() {
        this.props.onClose();
        this.setState({
            show: false
        })
    }

    handleViewed(event){
        const id = parseInt(event.target.value);
        const notif = this.state.news.find(data => {
            return data.id === id
        });

        const news = this.state.news.filter((notif) => notif.id !== id);

        this.setState({
            news: news,
            viewed: [
                notif,
                ...this.state.viewed
            ]
        })

        const url = process.env.REACT_APP_NOTIFICATIONS + "/" + id + "/viewed";
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios.post(url, [], config)
            .then(response => {
                console.log(response);

            })
            .catch(error => {

            });
    }

    loadNotificationsData() {
        const url = process.env.REACT_APP_NOTIFICATIONS;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios.get(url, config)
            .then(response => {
                console.log(response);
                const news = [];
                const viewed = [];

                response.data.data.forEach(item => {
                    if (item.viewed === 1){
                        viewed.push({
                            id: item.id,
                            viewed: item.viewed,
                            title: item.title,
                            text: item.text,
                            updated: item.updated_at.slice(0, 10)
                        })
                    }else{
                        news.push({
                            id: item.id,
                            viewed: item.viewed,
                            title: item.title,
                            text: item.text,
                            updated: item.updated_at.slice(0, 10)
                        })
                    }
                });

                this.setState({
                    news: news,
                    viewed: viewed,
                    updateNeeded: false
                });
            })
            .catch(error => {
                this.setState({
                    updateNeeded: false
                });
            });
    }


    render() {
        if (this.state.updateNeeded){
            this.loadNotificationsData()
        }
        return (
            <Offcanvas show={this.state.show} onHide={this.handleClose} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Уведомления</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {this.state.news.map(notification => { return (
                        <Card className={"mb-3 bg-light"} key={"notification-card-" + notification.id}>
                            <Card.Body>
                                <Card.Title>{notification.title}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"><small>Обновлено: {notification.updated}</small></Card.Subtitle>
                                <Card.Text style={{whiteSpace: "pre-wrap"}}>
                                    <small>{HTMLReactParser(notification.text)}</small>
                                </Card.Text>
                                <Button
                                    value={notification.id}
                                    variant="success"
                                    onClick={this.handleViewed}
                                    size="sm">Прочитано</Button>
                            </Card.Body>
                        </Card>
                    )})}

                    <h6>Просмотрено</h6>

                    {this.state.viewed.map(notification => { return (
                        <Card className={"mb-3 text-secondary"} key={"notification-card-viewed-" + notification.id}>
                            <Card.Body>
                                <Card.Title> {notification.title}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"><small>Обновлено: {notification.updated}</small></Card.Subtitle>
                                <Card.Text style={{whiteSpace: "pre-wrap"}}>
                                    <small>{HTMLReactParser(notification.text)}</small>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )})}

                </Offcanvas.Body>
            </Offcanvas>
        );
    }
}

export default UniversalNotification